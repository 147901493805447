import React,{useCallback,useState} from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import {Container,Row,Col,Button} from "reactstrap"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem

} from 'reactstrap';
import {useDropzone} from 'react-dropzone'

function App() {

    let vcfData = []

    const onDrop = useCallback(acceptedFiles => {
        vcfData = [];

        acceptedFiles.forEach(file => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result
                console.log(binaryStr)
                vcfData.push(binaryStr)

                if(vcfData.length === acceptedFiles.length){
                    console.log("download")
                    downloadMergedVCF()
                }
            }
            reader.readAsBinaryString(file)
        } )

        //after files have been processed: merge them, count them, update button, afterwards clear them




    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop,accept:'text/vcard,text/x-vcard,text/directory,text/directory;profile=vCard'});

    const downloadMergedVCF = () => {
        const element = document.createElement("a");
        const file = new Blob(vcfData, {type: 'text/vcard'});
        element.href = URL.createObjectURL(file);
        element.download = "contacts.vcf";
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
    };

    return (
        <div className="App">

            <Navbar color="dark" dark expand="md">
                <Container>
                    <NavbarBrand href="/">VCF-FILE-MERGER.COM</NavbarBrand>
                    <NavbarToggler/>

                    <Collapse  navbar>
                        <Nav className="mr-auto" navbar>
                            <NavItem>
                            <a href="https://florian-chrometz.de" target='_blank'><NavLink>vcf file merger free online tool by Florian Chrometz</NavLink></a>
                            </NavItem>

                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>


            <Container>
                <Row>
                    <Col xs={12} md={6} style={{marginTop:"50px"}}>
                        <h1>VCF File Merger</h1>
                        <h2>Free online vcf file combiner</h2>
                        <p>Have you ever had the issue that you wanted to join multiple VCF files into a sinlge VCF file?</p>
                        <p>With this free online VFC file merger you can simple combine multiple VCF files into a single file without installing or downloading any additional software.</p>
                        <p>The VFC combiner is free to use and lets you join as many files as you want.</p>
                        <p>We'll bulk import your vCard files into one single contact list file.</p>
                    </Col>
                    <Col xs={12} md={6} style={{padding:"15px 0 15px 0",marginTop:"50px"}}>

                        <div {...getRootProps()} style={{height:"100%",padding:"15px 15px 15px 15px",border: isDragActive ? "3px dotted white" : "3px dotted gray",backgroundColor: isDragActive ? "blue" : "white"}}>
                            <input {...getInputProps()} />
                            <p style={{color: isDragActive ? "white" : "gray"}}>Click here or simply drop your VCF files to merge them. <b>Make sure to select all VCF files at once</b>.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} style={{marginTop:"50px"}}>
                        <h3>How VCF Files work</h3>
                        <p>VCF-Files are filled with human readable text like "ADDRESS:Actual Data". They contain all data given for a specific contact, such as address, name, mobile number and much more.</p>
                        <p>The .vcf extension actually explains that the content follows a standard - called vCard. This standard represents a MIME Type standard and thereby needs to follow some rules to be seen as such.</p>
                        <p>There's two ways allowed to specify a vCard.</p>
                        <ol>
                            <li>Place given contact data into a single file per cotact</li>
                            <li>Place given data of multiple contacts in a single file</li>
                        </ol>
                    </Col>
                    <Col xs={12} md={6} style={{marginTop:"50px"}}>
                        <h3>How this website works - and why it's safe</h3>
                        <p>If you read about how VCF-Files work and what a vCard is then it's pretty easy to explain how vcf-file-merger.com is working with your files</p>
                        <p>As explained there's two ways how a VCF File can be set up. This page only works if you have VCF files of type 1 where each VCF file represents a single contact entry.</p>
                        <p>If you decide to place your files in the area above the files will be merged into a single VCF file of explained type 2. While doing so, none of your data will be uploaded since this process is done locally on your computer.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} style={{marginTop:"50px"}}>
                        <h3>How to merge VCF files</h3>
                        <p>Simply drop your files in the dropzone. <b>Make sure to drop all VCF file that you want to combine at once.</b> The online service will automatically merge your VFC files and start a download of the single VCF file that contains all uploaded contacts.</p>
                        <p>Good to know: The VFC merging actually is done on your device. We don't get or store any information of uploaded data.</p>
                    </Col>
                    <Col xs={12} md={6} style={{marginTop:"50px"}}>
                        <h3>Use cases for combined VCF files</h3>
                        <ul>
                            <li>Merge VCF contacts to upload them to Google Contacts</li>
                            <li>Merge VCF contacts to upload them to iCloud</li>
                            <li>Merge VCF contacts to use them in Excel</li>
                            <li>Merge VCF contacts to use them in Outlook</li>
                            <li>Merge VCF contacts to import them on your phone</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} style={{marginTop:"50px"}}>
                        <h3>How to move contacts from Google Contacts to iCloud</h3>
                        <p>If you ever tried to "simply" move contract from Google Contacts to iCloud because you switched devices then you most probably ended up in a situation where you downloaded tons of vcf files from Google Contacts but you're limited to upload only one file at once at iCloud.</p>
                        <p>In that case you're absolutely right here because that's actually what happened to me and made me build this page.</p>
                        <p>If you have these tons of vcf files, simply select them all and drop them into the dropzone on the upper right - you'll be asked to download a new contact file which will be a merged version of all your contacts.</p>
                        <p>Simply upload this single file to iCloud and all your contacts that you downloaded from Google Contacts will show up.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} style={{marginTop:"50px",marginBottom:"50px"}}>
                        <h3>How to split VCF Files</h3>
                        <p>If you have a merged VCF file in place it actually contains multiple contact files at once. Each individual contact file is separated by a specific text in that VCF file.</p>
                        <p>Try to open the given VCF file in a text editor and search for lines showing "BEGIN:VCARD" and "END:VCARD". Copy the entire content between these two lines as well as the two lines themselves. Then create a new .vcf file and place the content inside. That's it, you just created a single contact file.</p>
                    </Col>

                </Row>
            </Container>

        </div>
    );
}

export default App;
